import React from 'react';
import styled, { css } from 'styled-components';
import {
  sectionPaddings,
  brandColours,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  maxBreakpointQuery,
} from '../styles';
import { Svg, Container, Heading } from './ui';
import stripBlueHorizontalLarge from '../images/strip-blue-horizontal-large.inline.svg';
import stripBlueVertical from '../images/strip-blue-vertical.inline.svg';

const StyledLocations = styled.section`
  background-color: ${brandColours.secondary};

  ${minBreakpointQuery.xxlarge`
    position: relative;
  `}
`;

const StyledStrip = styled(Svg)`
  ${({ direction }) => {
    if (direction === 'horizontal') {
      return css`
        ${maxBreakpointQuery.xxlarge`
          height: 80px;
        `}

        ${maxBreakpointQuery.medium`
          height: 60px;
        `}

        ${minBreakpointQuery.xxlarge`
          display: none;
        `}
      `;
    } else {
      return css`
        ${maxBreakpointQuery.xxlarge`
          display: none;
        `}

        ${minBreakpointQuery.xxlarge`
          position: absolute;
          top: 0;
          right: 0;
          width: 80px;
        `}
      `;
    }
  }}
`;

const StyledInner = styled.div`
  ${sectionPaddings()};
`;

const StyledContent = styled.div`
  ${minBreakpointQuery.medium`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 100px;
    border-top: 1px solid ${brandColours.primary};
    padding-top: 30px;
    margin: 30px 0;
  `}

  ${minBreakpointQuery.mlarge`
    column-gap: 150px;
    padding-top: 40px;
    margin: 40px 0;
  `}

  ${minBreakpointQuery.large`
    column-gap: 200px;
    padding-top: 50px;
    margin: 50px 0;
  `}
`;

const StyledLocation = styled.div`
  ${maxBreakpointQuery.medium`
    border-top: 1px solid ${brandColours.primary};
    padding-top: 30px;
    margin: 30px 0;
  `}
`;

const StyledHeading = styled.h3`
  color: ${brandColours.primary};
  ${fontSize(24)};
  margin-bottom: 10px;
`;

const StyledAddress = styled.p`
  ${fontSize(20)};
  line-height: 1.6;
  font-weight: ${fontWeights.medium};
`;

const StyledDirections = styled.a`
  display: inline-block;
  margin-top: 30px;
  color: ${brandColours.primary};
  font-weight: ${fontWeights.medium};
  text-decoration: underline;
`;

const Locations = ({ locations }) => (
  <StyledLocations>
    <StyledStrip image={stripBlueHorizontalLarge} direction="horizontal" />
    <StyledStrip image={stripBlueVertical} direction="vertical" />
    <Container>
      <StyledInner>
        <Heading>Our Locations</Heading>
        <StyledContent>
          {locations.map(({ heading, address }, id) => (
            <StyledLocation key={id}>
              <StyledHeading>{heading}</StyledHeading>
              <StyledAddress>{address}</StyledAddress>
              <StyledDirections
                href={`https://www.google.co.uk/maps/dir//${address}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                Get Directions
              </StyledDirections>
            </StyledLocation>
          ))}
        </StyledContent>
      </StyledInner>
    </Container>
  </StyledLocations>
);

export default Locations;
