import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  sectionMargins,
  minBreakpointQuery,
  fontSize,
  inputStyles,
  textAreaStyles,
  formButtonStyles,
  fontWeights,
  brandColours,
  maxBreakpointQuery,
} from '../styles';
import { Container, Heading, ArrowButton, Svg } from './ui';
import mailSentIcon from '../images/mail-sent.inline.svg';
import arrowIcon from '../images/right-arrow.inline.svg';

const StyledContactForm = styled.section`
  ${sectionMargins()};
`;

const StyledWrapper = styled.div`
  ${minBreakpointQuery.medium`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 100px;
  `}

  ${minBreakpointQuery.mlarge`
    column-gap: 150px;
  `}

  ${minBreakpointQuery.large`
    column-gap: 200px;
  `}
`;

const StyledForm = styled.div``;

const StyledInner = styled.form`
  margin-top: 20px;
  display: grid;
  gap: 15px;

  ${minBreakpointQuery.small`
    margin-top: 30px;
    gap: 20px;
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
    gap: 25px;
  `}
`;

const StyledLabel = styled.label`
  grid-column: 1 / 3;
`;

const StyledLabelText = styled.span`
  font-weight: ${fontWeights.medium};
`;

const StyledInput = styled.input`
  ${inputStyles()};
`;

const StyledTextArea = styled.textarea`
  ${textAreaStyles()};
`;

const StyledSubmit = styled(ArrowButton)`
  ${formButtonStyles()};
  grid-column: 1 / 3;
`;

const StyledThankYou = styled.div``;

const StyledIcon = styled(Svg)`
  width: 43px;
  height: 43px;
`;

const StyledHeading = styled(Heading)`
  margin: 20px 0;

  ${minBreakpointQuery.small`
    margin-top: 25px;
    margin-bottom: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
    margin-bottom: 30px;
  `}
`;

const StyledText = styled.p`
  ${fontSize(18)};

  ${minBreakpointQuery.small`
    ${fontSize(20)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(22)};
  `}
`;

const StyledInfo = styled.div`
  ${maxBreakpointQuery.medium`
    margin: 40px 0;
  `}
`;

const StyledGroup = styled.div`
  margin-top: 15px;

  ${minBreakpointQuery.medium`
    margin-top: 30px;
    padding-left: 35px;
    padding-bottom: 20px;
    border-left: 3px solid ${brandColours.primary};
  `}
`;

const StyledSubGroup = styled.div`
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledGroupHeading = styled.h3`
  ${fontSize(25)}
  line-height: 2.2;
  color: ${brandColours.primary};
`;

const StyledGroupSubHeading = styled.h4`
  font-weight: ${fontWeights.medium};
  margin-bottom: 10px;
`;

const StyledLink = styled.a`
  ${fontSize(20)};
  color: ${brandColours.primary};
  text-decoration: underline;

  ${({ bold, arrow }) => {
    if (bold) {
      return css`
        font-weight: ${fontWeights.bold};
      `;
    } else if (arrow) {
      return css`
        display: flex;
        align-items: center;
        column-gap: 10px;
      `;
    }
  }}
`;

const StyledArrow = styled(Svg)`
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  fill: ${brandColours.primary};
`;

const ContactForm = ({
  phoneNumber,
  emailAddress,
  additionalContactInformation,
  linkedinProfile,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    const form = document.getElementById('contact-form');
    const formData = new FormData(form);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setFormSubmitted(true);
      })
      .catch(error => alert(error));
  };

  return (
    <StyledContactForm>
      <Container>
        <StyledWrapper>
          <StyledForm>
            {!formSubmitted ? (
              <>
                <Heading>Send a Message</Heading>
                <StyledInner
                  id="contact-form"
                  name="contact-form"
                  method="POST"
                  data-netlify="true"
                  onSubmit={e => handleSubmit(e)}
                >
                  <input type="hidden" name="form-name" value="contact-form" />
                  <StyledLabel>
                    <StyledLabelText>Full name</StyledLabelText>
                    <StyledInput type="text" name="name" required />
                  </StyledLabel>
                  <StyledLabel>
                    <StyledLabelText>E-mail address</StyledLabelText>
                    <StyledInput type="email" name="email" required />
                  </StyledLabel>
                  <StyledLabel>
                    <StyledLabelText>Message</StyledLabelText>
                    <StyledTextArea name="message" />
                  </StyledLabel>
                  <StyledSubmit>Submit Form</StyledSubmit>
                </StyledInner>
              </>
            ) : (
              <StyledThankYou>
                <StyledIcon image={mailSentIcon} />
                <StyledHeading>Thank you for your Enquiry</StyledHeading>
                <StyledText>Thank you, we’ll be in touch</StyledText>
              </StyledThankYou>
            )}
          </StyledForm>
          <StyledInfo>
            <Heading>Get in touch</Heading>
            <StyledGroup>
              <StyledGroupHeading>Call</StyledGroupHeading>
              <StyledSubGroup>
                <StyledGroupSubHeading>General Enquiries</StyledGroupSubHeading>
                <StyledLink bold={true} href={`tel:${phoneNumber}`}>
                  {phoneNumber}
                </StyledLink>
              </StyledSubGroup>
              {additionalContactInformation.map(
                (contactInfo, id) =>
                  contactInfo.phoneNumber && (
                    <StyledSubGroup key={id}>
                      <StyledGroupSubHeading>
                        {contactInfo.heading}
                      </StyledGroupSubHeading>
                      <StyledLink
                        bold={true}
                        href={`tel:${contactInfo.phoneNumber}`}
                      >
                        {contactInfo.phoneNumber}
                      </StyledLink>
                    </StyledSubGroup>
                  )
              )}
            </StyledGroup>
            <StyledGroup>
              <StyledGroupHeading>E-mail</StyledGroupHeading>
              <StyledSubGroup>
                <StyledGroupSubHeading>General Enquiries</StyledGroupSubHeading>
                <StyledLink arrow={true} href={`mailto:${emailAddress}`}>
                  <StyledArrow image={arrowIcon} />
                  {emailAddress}
                </StyledLink>
              </StyledSubGroup>
              {additionalContactInformation.map(
                (contactInfo, id) =>
                  contactInfo.emailAddress && (
                    <StyledSubGroup key={id}>
                      <StyledGroupSubHeading>
                        {contactInfo.heading}
                      </StyledGroupSubHeading>
                      <StyledLink
                        arrow={true}
                        href={`mailto:${contactInfo.emailAddress}`}
                      >
                        <StyledArrow image={arrowIcon} />
                        {contactInfo.emailAddress}
                      </StyledLink>
                    </StyledSubGroup>
                  )
              )}
            </StyledGroup>
            <StyledGroup>
              <StyledGroupHeading>LinkedIn</StyledGroupHeading>
              <StyledSubGroup>
                <p>
                  Connect on{' '}
                  <StyledLink href={linkedinProfile} target="_blank">
                    LinkedIn
                  </StyledLink>
                </p>
              </StyledSubGroup>
            </StyledGroup>
          </StyledInfo>
        </StyledWrapper>
      </Container>
    </StyledContactForm>
  );
};

export default ContactForm;
