import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ContactForm from '../components/ContactForm';
import Locations from '../components/Locations';

const ContactPage = () => {
  const {
    datoCmsContact: { seoMetaTags, title, bannerText },
    datoCmsContactInformation: {
      phoneNumber,
      emailAddress,
      additionalContactInformation,
      linkedinProfile,
      locations,
    },
  } = useStaticQuery(graphql`
    query ContactPageQuery {
      datoCmsContact {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerText
      }
      datoCmsContactInformation {
        phoneNumber
        emailAddress
        additionalContactInformation {
          heading
          phoneNumber
          emailAddress
        }
        linkedinProfile
        locations {
          heading
          address
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner heading={title} text={bannerText} isContact={true} />
        <ContactForm
          phoneNumber={phoneNumber}
          emailAddress={emailAddress}
          additionalContactInformation={additionalContactInformation}
          linkedinProfile={linkedinProfile}
        />
        <Locations locations={locations} />
      </main>
    </Layout>
  );
};

export default ContactPage;
